exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-1-aylik-kopege-sut-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/1-aylik-kopege-sut-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-1-aylik-kopege-sut-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-2-aylik-kopege-sut-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/2-aylik-kopege-sut-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-2-aylik-kopege-sut-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-3-aylik-kopege-sut-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/3-aylik-kopege-sut-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-3-aylik-kopege-sut-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-5-aylik-kopege-yetiskin-mamasi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/5-aylik-kopege-yetiskin-mamasi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-5-aylik-kopege-yetiskin-mamasi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-alman-kurdu-neyle-beslenir-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/alman-kurdu-neyle-beslenir.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-alman-kurdu-neyle-beslenir-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-balik-kafasi-kopege-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/balik-kafasi-kopege-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-balik-kafasi-kopege-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-balik-yagi-kopeklere-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/balik-yagi-kopeklere-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-balik-yagi-kopeklere-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-corbalik-tavuk-kopege-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/corbalik-tavuk-kopege-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-corbalik-tavuk-kopege-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-domates-kopeklere-zararli-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/domates-kopeklere-zararli-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-domates-kopeklere-zararli-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-emziren-kopege-sut-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/emziren-kopege-sut-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-emziren-kopege-sut-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-hamile-kopege-parazit-hapi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/hamile-kopege-parazit-hapi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-hamile-kopege-parazit-hapi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-hamile-kopege-yumurta-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/hamile-kopege-yumurta-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-hamile-kopege-yumurta-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-haslanmis-yumurta-kopege-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/haslanmis-yumurta-kopege-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-haslanmis-yumurta-kopege-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-hindi-kemigi-kopege-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/hindi-kemigi-kopege-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-hindi-kemigi-kopege-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-karpuz-kopeklere-zararli-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/karpuz-kopeklere-zararli-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-karpuz-kopeklere-zararli-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kedi-mamasi-kopeklere-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kedi-mamasi-kopeklere-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kedi-mamasi-kopeklere-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kedi-sut-tozu-kopege-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kedi-sut-tozu-kopege-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kedi-sut-tozu-kopege-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kedilere-kopek-mamasi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kedilere-kopek-mamasi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kedilere-kopek-mamasi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kediye-kopek-yas-mamasi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kediye-kopek-yas-mamasi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kediye-kopek-yas-mamasi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kemik-unu-kopeklere-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kemik-unu-kopeklere-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kemik-unu-kopeklere-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kereviz-kopeklere-zararli-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kereviz-kopeklere-zararli-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kereviz-kopeklere-zararli-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kokmus-tavuk-kopege-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kokmus-tavuk-kopege-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kokmus-tavuk-kopege-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-haslanmis-tavuk-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopege-haslanmis-tavuk-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-haslanmis-tavuk-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-her-gun-yas-mama-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopege-her-gun-yas-mama-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-her-gun-yas-mama-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-kefir-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopege-kefir-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-kefir-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-kuzu-kemigi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopege-kuzu-kemigi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-kuzu-kemigi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-pirinc-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopege-pirinc-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-pirinc-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-pirzola-kemigi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopege-pirzola-kemigi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-pirzola-kemigi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-pismis-tavuk-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopege-pismis-tavuk-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-pismis-tavuk-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-pogaca-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopege-pogaca-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-pogaca-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-protein-tozu-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopege-protein-tozu-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-protein-tozu-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-salatalik-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopege-salatalik-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-salatalik-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-tavuk-cigeri-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopege-tavuk-cigeri-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-tavuk-cigeri-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-tavuk-derisi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopege-tavuk-derisi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-tavuk-derisi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-yogurt-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopege-yogurt-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopege-yogurt-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopek-sut-tozu-kediye-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopek-sut-tozu-kediye-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopek-sut-tozu-kediye-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopek-yas-mamasi-kediye-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopek-yas-mamasi-kediye-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopek-yas-mamasi-kediye-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopek-yavrusuna-sut-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopek-yavrusuna-sut-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopek-yavrusuna-sut-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-ciger-yiyebilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-ciger-yiyebilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-ciger-yiyebilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-cikolata-yiyebilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-cikolata-yiyebilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-cikolata-yiyebilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-elma-yiyebilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-elma-yiyebilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-elma-yiyebilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-fistik-ezmesi-yiyebilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-fistik-ezmesi-yiyebilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-fistik-ezmesi-yiyebilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-hangi-meyveleri-yiyemez-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-hangi-meyveleri-yiyemez.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-hangi-meyveleri-yiyemez-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-hangi-sebzeleri-yiyebilir-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-hangi-sebzeleri-yiyebilir.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-hangi-sebzeleri-yiyebilir-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-havuc-yiyebilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-havuc-yiyebilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-havuc-yiyebilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-kayisi-yiyebilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-kayisi-yiyebilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-kayisi-yiyebilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-kemik-yiyebilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-kemik-yiyebilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-kemik-yiyebilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-kestane-yiyebilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-kestane-yiyebilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-kestane-yiyebilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-meyve-yiyebilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-meyve-yiyebilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-meyve-yiyebilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-muz-yiyebilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-muz-yiyebilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-muz-yiyebilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-ne-yiyebilir-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-ne-yiyebilir.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-ne-yiyebilir-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-uzum-yiyebilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-uzum-yiyebilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-uzum-yiyebilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-yumurta-yiyebilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopekler-yumurta-yiyebilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopekler-yumurta-yiyebilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-agri-kesici-ne-verilir-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-agri-kesici-ne-verilir.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-agri-kesici-ne-verilir-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-arpa-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-arpa-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-arpa-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-ayran-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-ayran-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-ayran-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-b-vitamini-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-b-vitamini-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-b-vitamini-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-balik-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-balik-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-balik-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-bebe-biskuvisi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-bebe-biskuvisi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-bebe-biskuvisi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-bebek-mamasi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-bebek-mamasi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-bebek-mamasi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-biskuvi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-biskuvi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-biskuvi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-bulgur-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-bulgur-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-bulgur-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-c-vitamini-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-c-vitamini-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-c-vitamini-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-cig-et-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-cig-et-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-cig-et-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-cig-kemik-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-cig-kemik-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-cig-kemik-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-cig-sut-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-cig-sut-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-cig-sut-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-cig-tavuk-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-cig-tavuk-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-cig-tavuk-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-cig-yumurta-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-cig-yumurta-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-cig-yumurta-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-d-vitamini-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-d-vitamini-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-d-vitamini-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-ekmek-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-ekmek-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-ekmek-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-enginar-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-enginar-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-enginar-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-erik-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-erik-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-erik-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-et-suyu-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-et-suyu-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-et-suyu-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-ev-yemegi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-ev-yemegi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-ev-yemegi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-gece-su-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-gece-su-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-gece-su-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-her-gun-yumurta-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-her-gun-yumurta-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-her-gun-yumurta-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-hindistan-cevizi-yagi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-hindistan-cevizi-yagi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-hindistan-cevizi-yagi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-kefir-nasil-verilir-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-kefir-nasil-verilir.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-kefir-nasil-verilir-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-kefir-ne-kadar-verilir-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-kefir-ne-kadar-verilir.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-kefir-ne-kadar-verilir-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-kefir-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-kefir-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-kefir-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-kemik-suyu-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-kemik-suyu-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-kemik-suyu-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-laktozsuz-sut-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-laktozsuz-sut-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-laktozsuz-sut-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-leblebi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-leblebi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-leblebi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-mantar-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-mantar-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-mantar-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-misir-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-misir-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-misir-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-ne-kadar-yogurt-verilir-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-ne-kadar-yogurt-verilir.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-ne-kadar-yogurt-verilir-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-omega-3-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-omega-3-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-omega-3-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-pasiflora-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-pasiflora-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-pasiflora-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-peynir-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-peynir-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-peynir-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-pilav-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-pilav-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-pilav-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-pismis-et-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-pismis-et-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-pismis-et-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-probiyotik-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-probiyotik-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-probiyotik-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-sakinlestirici-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-sakinlestirici-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-sakinlestirici-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-salam-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-salam-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-salam-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-sicak-yemek-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-sicak-yemek-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-sicak-yemek-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-soguk-su-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-soguk-su-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-soguk-su-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-tavuk-eti-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-tavuk-eti-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-tavuk-eti-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-tavuk-kemigi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-tavuk-kemigi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-tavuk-kemigi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-tavuk-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-tavuk-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-tavuk-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-tuz-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-tuz-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-tuz-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-uyku-hapi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-uyku-hapi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-uyku-hapi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-vitamin-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-vitamin-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-vitamin-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-yulaf-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-yulaf-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-yulaf-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-yumurta-kabugu-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-yumurta-kabugu-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-yumurta-kabugu-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-yumurta-sarisi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kopeklere-yumurta-sarisi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kopeklere-yumurta-sarisi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-kusan-kopege-yogurt-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/kusan-kopege-yogurt-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-kusan-kopege-yogurt-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-makarna-kopeklere-zararli-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/makarna-kopeklere-zararli-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-makarna-kopeklere-zararli-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-midesi-bulanan-kopege-ne-verilir-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/midesi-bulanan-kopege-ne-verilir.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-midesi-bulanan-kopege-ne-verilir-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-passiflora-kopeklere-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/passiflora-kopeklere-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-passiflora-kopeklere-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-supradyn-kopeklere-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/supradyn-kopeklere-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-supradyn-kopeklere-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-balik-yagi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-balik-yagi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-balik-yagi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-cig-yumurta-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-cig-yumurta-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-cig-yumurta-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-ev-yemegi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-ev-yemegi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-ev-yemegi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-gece-mama-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-gece-mama-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-gece-mama-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-haslanmis-et-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-haslanmis-et-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-haslanmis-et-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-keci-sutu-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-keci-sutu-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-keci-sutu-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-kedi-mamasi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-kedi-mamasi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-kedi-mamasi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-makarna-verilirmi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-makarna-verilirmi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-makarna-verilirmi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-odul-mamasi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-odul-mamasi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-odul-mamasi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-peynir-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-peynir-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-peynir-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-pirinc-unu-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-pirinc-unu-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-pirinc-unu-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-salam-verilirmi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-salam-verilirmi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-salam-verilirmi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-sutlu-ekmek-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-sutlu-ekmek-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-sutlu-ekmek-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-tavuk-kemigi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-tavuk-kemigi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-tavuk-kemigi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-vitamin-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-vitamin-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-vitamin-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-yetiskin-kopek-mamasi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-yetiskin-kopek-mamasi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-yetiskin-kopek-mamasi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-yumurta-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopege-yumurta-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopege-yumurta-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopek-mamasi-yetiskin-kopege-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopek-mamasi-yetiskin-kopege-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopek-mamasi-yetiskin-kopege-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopek-sut-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopek-sut-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopek-sut-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopek-yas-mama-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopek-yas-mama-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopek-yas-mama-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopekler-kemik-yiyebilirmi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopekler-kemik-yiyebilirmi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopekler-kemik-yiyebilirmi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-bebek-mamasi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopeklere-bebek-mamasi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-bebek-mamasi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-devit-3-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopeklere-devit-3-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-devit-3-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-ekmek-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopeklere-ekmek-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-ekmek-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-gece-su-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopeklere-gece-su-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-gece-su-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-su-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopeklere-su-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-su-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-sut-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopeklere-sut-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-sut-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-tavuk-eti-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopeklere-tavuk-eti-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-tavuk-eti-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-yogurt-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yavru-kopeklere-yogurt-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yavru-kopeklere-yogurt-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yeni-dogan-kopege-su-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yeni-dogan-kopege-su-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yeni-dogan-kopege-su-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yeni-dogan-kopege-sut-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yeni-dogan-kopege-sut-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yeni-dogan-kopege-sut-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yetiskin-kopege-yavru-kopek-mamasi-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yetiskin-kopege-yavru-kopek-mamasi-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yetiskin-kopege-yavru-kopek-mamasi-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yetiskin-kopek-mamasi-yavru-kopege-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yetiskin-kopek-mamasi-yavru-kopege-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yetiskin-kopek-mamasi-yavru-kopege-verilir-mi-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-content-yumurta-kopeklere-verilir-mi-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/Users/kervanaslan/Desktop/projects/kopeklerneyer/src/content/yumurta-kopeklere-verilir-mi.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-content-yumurta-kopeklere-verilir-mi-mdx" */),
  "component---src-templates-main-tsx": () => import("./../../../src/templates/main.tsx" /* webpackChunkName: "component---src-templates-main-tsx" */)
}

